// App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import WelcomePage from './components/WelcomePage';
import Form from './components/Form';

function App() {
  const links = [
    { to: '/', label: 'Home' },
    { to: '/medical-form', label: 'Medical Form' }
    // Add more links as needed
  ];

  return (
    <Router>
      <div className="App">
        <Sidebar links={links} />
        <div className="main-content">
          <Routes>
            <Route path="/medical-form" element={<Form />} />
            <Route path="/" element={<WelcomePage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;