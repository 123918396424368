// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WelcomePage.css */
.WelcomePage {
    padding: 20px;
    margin: 0 auto;
    max-width: 500px;
  }
  
  .WelcomePage h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .WelcomePage p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/WelcomePage.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":["/* WelcomePage.css */\r\n.WelcomePage {\r\n    padding: 20px;\r\n    margin: 0 auto;\r\n    max-width: 500px;\r\n  }\r\n  \r\n  .WelcomePage h1 {\r\n    font-size: 24px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .WelcomePage p {\r\n    font-size: 16px;\r\n    line-height: 1.5;\r\n    margin-bottom: 15px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
