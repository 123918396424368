// components/CheckboxQuestion.js
import React from 'react';

function CheckboxQuestion({ question, options, selectedOptions, onChange, required}) {
  const handleCheckboxChange = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(item => item !== option)
      : [...selectedOptions, option];
    onChange(newSelectedOptions);
  };

  return (
    <div>
      <label>{question}</label>
      {options.map(option => (
        <div key={option}>
          <input
            type="checkbox"
            id={option}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={() => handleCheckboxChange(option)}
            required={required}
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}
    </div>
  );
}

export default CheckboxQuestion;
