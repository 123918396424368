// Form.js
import React, { useState } from 'react';
import './Form.css';
import axios from 'axios';
import RadioQuestion from './RadioQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import DropdownQuestion from './DropdownQuestion';
// {id, type, label, options, required}
const questions = [
  { id: '', type: 'br', label: 'Patient Information', required: false},
  { id: 'fname', type: 'text', label: 'First Name *', required: true},
  { id: 'mname', type: 'text', label: 'Middle Initial', required: false},
  { id: 'lname', type: 'text', label: 'Last Name *', required: true},
  { id: 'email', type: 'text', label: 'Email *', required: true},
  { id: 'dob', type: 'text', label: 'Date of Birth *', required: true},
  { id: 'ssn', type: 'text', label: 'Last 4 SSN *', required: true },
  { id: 'gender', type: 'dropdown', label: 'Gender *', options: ['Male', 'Female', 'Other'], required: true },
  { id: 'addr', type: 'text', label: 'Mailing Address *', required: true },
  { id: 'city', type: 'text', label: 'City *', required: true },
  { id: 'state', type: 'text', label: 'State *', required: true },
  { id: 'zip', type: 'text', label: 'Zip Code *', required: true },
  { id: 'phone', type: 'text', label: 'Phone Number *', required: true },
  { id: 'emname', type: 'text', label: 'Emergency Contact Name *', required: true },
  { id: 'emrel', type: 'text', label: 'Relationship', required: false },
  { id: 'emph', type: 'text', label: 'Emergency Contact Number *', required: true },
  { id: 'attname', type: 'text', label: 'Attorney Name (if none, leave blank)', required: false},
  { id: 'attph', type: 'text', label: 'Attorney Phone Number (if none, leave blank)', required: false},
  { id: 'ref', type: 'text', label: 'Who Referred You to Us?', required: false},
  { id: '', type: 'br', label: 'Insurance Information', required: false},
  { id: 'symptoms', type: 'checkbox', label: 'Symptoms', options: ['Fever', 'Cough', 'Headache', 'Fatigue'], required: true },
  { id: 'additionalRadio', type: 'radio', label: 'Additional Radio Question', options: ['Option 1', 'Option 2', 'Option 3'], required: true },
  { id: 'additionalCheckbox', type: 'checkbox', label: 'Additional Checkbox Question', options: ['Option A', 'Option B', 'Option C'], required: true }
];

function Form() {
  const [formData, setFormData] = useState({});
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? (checked ? [...(prevState[name] || []), value] : (prevState[name] || []).filter(item => item !== value)) : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send form data to the server
      await axios.post('/api/submitForm', formData);
      // Reset form after submission
      setFormData({});
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Form submission failed:', error);
      alert('Form submission failed. Please try again later.');
    }
  };

  

  return (
    <div className="Form">
      <h2>New Patient Registration Form</h2>
      <p>Please fill out this form completely. The following information will help us in providing you the best medical care and treatment possible. If you have any questions, please contact the office. Thank you and we look forward to seeing you!</p>
      <form onSubmit={handleSubmit}>
        {questions.map(question => {
          if (question.type === 'text') {
            return (
              <input
                key={question.id}
                type="text"
                name={question.id}
                value={formData[question.id] || ''}
                onChange={handleChange}
                placeholder={question.label}
                required={question.required}
              />
            );
          } else if (question.type === 'radio') {
            return (
              <RadioQuestion
                key={question.id}
                question={question.label}
                options={question.options}
                value={formData[question.id] || ''}
                onChange={e => setFormData({ ...formData, [question.id]: e.target.value })}
                required={question.required}
              />
            );
          } else if (question.type === 'checkbox') {
            return (
              <CheckboxQuestion
                key={question.id}
                question={question.label}
                options={question.options}
                selectedOptions={formData[question.id] || []}
                onChange={selectedOptions => setFormData({ ...formData, [question.id]: selectedOptions })}
                required={question.required}
              />
            );
          } else if (question.type === 'dropdown'){
            return (
              <DropdownQuestion
                key={question.id}
                question={question.label}
                options={question.options}
                value={formData[question.id] || ""}
                onChange={selectedOption => setFormData({ ...formData, [question.id]: selectedOption })}
                required={question.required}
              />
            );
          } else if (question.type === 'br'){
            return <h2><hr className='line'></hr>{question.label}</h2>
          }
          return null;
        })}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}


export default Form;

/*
        <div className="side-by-side">
          <RadioQuestion
            question="Additional Radio Question"
            options={['Option 1', 'Option 2', 'Option 3']}
            value={formData.additionalRadio || ''}
            onChange={e => setFormData({ ...formData, additionalRadio: e.target.value })}
          />
          <CheckboxQuestion
            question="Additional Checkbox Question"
            options={['Option A', 'Option B', 'Option C']}
            selectedOptions={formData.additionalCheckbox || []}
            onChange={selectedOptions => setFormData({ ...formData, additionalCheckbox: selectedOptions })}
          />
        </div>
        */