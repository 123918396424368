// components/RadioQuestion.js
import React from 'react';

function RadioQuestion({ question, options, value, onChange, required }) {
  return (
    <div>
      <label>{question}</label>
      {options.map(option => (
        <div key={option}>
          <input
            type="radio"
            id={option}
            name={question}
            value={option}
            checked={value === option}
            onChange={onChange}
            required={required}
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}
    </div>
  );
}

export default RadioQuestion;
