// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.css */
.Sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #f0f0f0;
    padding: 20px;
    transition: transform 0.3s ease;
    z-index: 1;
  }
  
  .Sidebar.collapsed {
    transform: translateX(-250px); /* Shift off-screen */
    background-color: transparent;
  }
  
  .collapse-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  ul li a {
    text-decoration: none;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,+BAA+B;IAC/B,UAAU;EACZ;;EAEA;IACE,6BAA6B,EAAE,qBAAqB;IACpD,6BAA6B;EAC/B;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb","sourcesContent":["/* Sidebar.css */\r\n.Sidebar {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 250px;\r\n    height: 100%;\r\n    background-color: #f0f0f0;\r\n    padding: 20px;\r\n    transition: transform 0.3s ease;\r\n    z-index: 1;\r\n  }\r\n  \r\n  .Sidebar.collapsed {\r\n    transform: translateX(-250px); /* Shift off-screen */\r\n    background-color: transparent;\r\n  }\r\n  \r\n  .collapse-button {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    font-size: 20px;\r\n  }\r\n  \r\n  ul {\r\n    list-style-type: none;\r\n    padding: 0;\r\n  }\r\n  \r\n  ul li {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  ul li a {\r\n    text-decoration: none;\r\n    color: #333;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
