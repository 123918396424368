// Sidebar.js
import React, { useState } from 'react';
import './Sidebar.css';

import { Link } from 'react-router-dom';

function Sidebar({ links }) {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    document.querySelector('.main-content').classList.toggle('sbCollapsed');
  };

  return (
    <div className={`Sidebar ${collapsed ? 'collapsed' : ''}`}>
      <button className="collapse-button" onClick={toggleCollapsed}>
        {collapsed ? '▶' : '◀'}
      </button>
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.to} onClick={toggleCollapsed}>{link.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;