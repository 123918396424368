// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.App {
  display: flex; /* Use flexbox for layout */
}

.Sidebar {
  width: 250px; /* Set fixed width for the sidebar */
  background-color: #f0f0f0;
  padding: 20px;
}

.main-content {
  flex: 1 1; /* Fill remaining space */
  padding: 20px;
}

/*.main-content.sbCollapsed {
  transform: translateX(-50px);
}*/`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,aAAa,EAAE,2BAA2B;AAC5C;;AAEA;EACE,YAAY,EAAE,oCAAoC;EAClD,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,SAAO,EAAE,yBAAyB;EAClC,aAAa;AACf;;AAEA;;EAEE","sourcesContent":["/* App.css */\n.App {\n  display: flex; /* Use flexbox for layout */\n}\n\n.Sidebar {\n  width: 250px; /* Set fixed width for the sidebar */\n  background-color: #f0f0f0;\n  padding: 20px;\n}\n\n.main-content {\n  flex: 1; /* Fill remaining space */\n  padding: 20px;\n}\n\n/*.main-content.sbCollapsed {\n  transform: translateX(-50px);\n}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
