// components/WelcomePage.js
import React from 'react';
import './WelcomePage.css';

function WelcomePage() {
  return (
    <div className="WelcomePage">
      <h1>Welcome to the Patient Portal</h1>
      <p>This application allows you to fill out medical forms and submit them securely.</p>
      <p>Click on "Medical Form" in the sidebar to get started.</p>
    </div>
  );
}

export default WelcomePage;
