// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

        /* Style for the select box */
        select {
            width: 95%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
        }

        /* Style for the placeholder option */
        select option[disabled] {
            color: #aaa;
        }`, "",{"version":3,"sources":["webpack://./src/components/DropdownQuestion.css"],"names":[],"mappings":";;QAEQ,6BAA6B;QAC7B;YACI,UAAU;YACV,aAAa;YACb,sBAAsB;YACtB,kBAAkB;YAClB,eAAe;QACnB;;QAEA,qCAAqC;QACrC;YACI,WAAW;QACf","sourcesContent":["\r\n\r\n        /* Style for the select box */\r\n        select {\r\n            width: 95%;\r\n            padding: 10px;\r\n            border: 1px solid #ccc;\r\n            border-radius: 5px;\r\n            font-size: 16px;\r\n        }\r\n\r\n        /* Style for the placeholder option */\r\n        select option[disabled] {\r\n            color: #aaa;\r\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
